<template>
  <div>
    <Input
      :maxlength="20"
      v-model.trim="searchData.name"
      placeholder="姓名"
      style="width: 150px; margin: 0 10px"
    ></Input>
    <Input
      :maxlength="20"
      v-model.trim="searchData.mobile"
      placeholder="手机号"
      style="width: 150px; margin-right: 10px"
    ></Input>
    <Input
      :maxlength="20"
      v-model.trim="searchData.archiveNum"
      placeholder="档案编号"
      style="width: 150px; margin-right: 10px"
    ></Input>
    <Select
      v-model="searchData.difficultType"
      style="width: 150px; margin-right: 10px"
      placeholder="请选择分类"
    >
      <Option
        v-for="item in classificationList"
        :value="item.value"
        :key="item.value"
        >{{ item.label }}</Option
      >
    </Select>
    <Select
      v-model="searchData.difficultLevel"
      style="width: 150px; margin-right: 10px"
      placeholder="请选择分级"
    >
      <Option v-for="item in gradeList" :value="item.value" :key="item.value">{{
        item.label
      }}</Option>
    </Select>
    <Button type="primary" style="margin-right: 10px" @click="search"
      >查询</Button
    >
    <Button type="success" @click="reset">重置</Button>

    <div class="container">
      <LiefengTable
        ref="table"
        :talbeColumns="tableColumns"
        :tableData="tableData"
        :total="total"
        :curPage="page"
        @hadlePageSize="hadlePageSize"
        @tableSelect="tableSelect"
        :pagesizeOpts="[20, 30, 50, 100]"
        :loading="loading"
        :pageSize="pageSize"
      ></LiefengTable>
      <div class="button">
        <Button type="success" @click="delRight">删除</Button>
        <Button type="primary" @click="clearSelect">清空</Button>
      </div>
      <div class="right">
        <div class="rightTitle">已选择</div>
        <List border size="small">
          <ListItem v-for="(item, index) in selectList" :key="item.archiveId"
            ><div
              style="width: 100%; display: block"
              @click="selectRightList($event, index)"
            >
              {{ item.name }}
            </div></ListItem
          >
        </List>
      </div>
    </div>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  props: ["targetList"],
  data() {
    return {
      searchData: {},
      classificationList: [
        { value: "1", label: "低保低收入对象" },
        { value: "2", label: "特困人员" },
        { value: "3", label: "留守老年人" },
        { value: "4", label: "独居孤寡老人" },
        { value: "5", label: "困境儿童" },
        { value: "6", label: "贫困重度残疾人" },
        { value: "7", label: "其他" },
      ],
      gradeList: [
        { value: "1", label: "一级" },
        { value: "2", label: "二级" },
        { value: "3", label: "三级" },
        { value: "4", label: "四级" },
      ],
      tableData: [],
      tableColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 120,
        },
        {
          title: "档案编号",
          key: "archiveNum",
          minWidth: 120,
        },
        {
          title: "所属社区",
          key: "orgName",
          minWidth: 150,
        },
        {
          title: "分类",
          key: "difficultType",
          minWidth: 120,
        },
        {
          title: "分级",
          key: "difficultLevel",
          width: 150,
        },
        {
          title: "手机号",
          key: "mobile",
          width: 150,
        },
      ],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 20,

      //选中列表
      selectList: [],
      removeRightList: [],
    };
  },
  methods: {
    save() {
      return this.selectList;
    },
    reset() {
      this.searchData = {};
      this.hadlePageSize({
        page: 1,
        pageSize: 20,
      });
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    //取消选中--清空
    clearSelect() {
      this.selectList = [];
      this.$refs.table.$children.map((item) => {
        if (this.$(item.$el).hasClass("ivu-table-wrapper")) {
          item.selectAll(false);
        }
      });
    },
    // 删除
    delRight() {
      if (this.removeRightList.length == 0) {
        this.$Message.error({
          background: true,
          content: "请从已选择列表中选中需要删除的目标人员",
        });
        return;
      }
      let arr = [];
      this.removeRightList.map((item) => {
        arr.push(item.archiveId);
      });
      let arr2 = [];
      this.selectList.map((item) => {
        if (arr.indexOf(item.archiveId) == -1) {
          arr2.push(item);
        }
      });
      this.selectList = arr2;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      })
      
      this.removeRightList = [];
    },
    // 选中右侧
    selectRightList(e, index) {
      if (e.target.parentNode.classList.contains("active")) {
        e.target.parentNode.classList.remove("active");
        this.removeRightList.splice(index, 1);
      } else {
        e.target.parentNode.classList.add("active");
        this.removeRightList.push(this.selectList[index]);
      }
    },
    //tabel选中
    tableSelect(arr) {
      arr.map((item) => {
        if (JSON.stringify(this.selectList).indexOf(item.archiveId) == -1) {
          this.selectList.push(item);
        }
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$post("/archives/api/pc/servicePlan/targetPage", {
        oemCode: parent.vue.oemInfo.oemCode,
        operatorId: parent.vue.loginInfo.userinfo.custGlobalId,
        operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
        ...this.searchData,
        page: obj.page,
        pageSize: obj.pageSize,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              if (item.difficultType) {
                switch (item.difficultType) {
                  case "1":
                    item.difficultType = "低保低收入对象";
                    break;
                  case "2":
                    item.difficultType = "特困人员";
                    break;
                  case "3":
                    item.difficultType = "留守老年人";
                    break;
                  case "4":
                    item.difficultType = "独居孤寡老人";
                    break;
                  case "5":
                    item.difficultType = "困境儿童";
                    break;
                  case "6":
                    item.difficultType = "贫困重度残疾人";
                    break;
                  case "7":
                    item.difficultType = "其他";
                    break;
                }
              }
              if (item.difficultLevel) {
                switch (item.difficultLevel) {
                  case "1":
                    item.difficultLevel = "一级";
                    break;
                  case "2":
                    item.difficultLevel = "二级";
                    break;
                  case "3":
                    item.difficultLevel = "三级";
                    break;
                  case "4":
                    item.difficultLevel = "四级";
                    break;
                }
              }
            });
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.tableData.map((item) => {
              if (
                JSON.stringify(this.selectList).indexOf(item.archiveId) != -1
              ) {
                item._checked = true;
              }
            });
            this.loading = false;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    if (this.targetList && this.targetList.length > 0)
      this.selectList = this.targetList;
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.container {
  margin-top: 14px;
  display: flex;
  justify-content: flex-start;
  & > div {
    width: 80%;
    /deep/.ivu-table-wrapper {
      height: calc(100vh - 220px);
    }
    /deep/.ivu-table-body {
      height: calc(100vh - 260px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .button {
    width: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .ivu-btn {
      margin-bottom: 16px;
    }
  }
  .right {
    width: 200px;
    height: calc(100vh - 220px);
    border: 1px solid #dcdee2;
    overflow: auto;
    .rightTitle {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      line-height: 40px;
      color: #515a6e;
      background: #f8f8f9;
    }
    /deep/.active {
      background: #19be6b;
      color: #fff;
    }
  }
}
</style>